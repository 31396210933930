
  if (!Element.prototype.matches) {
    console.log('polyfill for IE11');
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;
  }
  
  // проверяем поддержку
  if (!Element.prototype.closest) {
    console.log('polyfill for IE11');
    // реализуем
    Element.prototype.closest = function(css) {
      var node = this;
      while (node) {
        if (node.matches(css)) return node;
        else node = node.parentElement;
      }
      return null;
    };
  }
